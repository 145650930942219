.logo {
  max-width: 50%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Add media queries to ensure responsive design */
@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 10px;
  }
}

@media (min-width: 769px) {
  .container {
    width: 50%;
    margin: 0 auto;
  }
}

/* Uniform text sizes */
.text-xl {
  font-size: 1.25rem; /* 20px */
}

.text-lg {
  font-size: 1.125rem; /* 18px */
}

.text-4xl {
  font-size: 2.25rem; /* 36px */
}

.text-center {
  text-align: center;
}

.bg-indigo-700 {
  background-color: #4c51bf;
}

.bg-indigo-800 {
  background-color: #3730a3;
}

.bg-gradient-to-b {
  background: linear-gradient(to bottom, #4c51bf, #3730a3);
}

.border-yellow-400 {
  border-color: #ecc94b;
}

/* New input field styling */
input {
  border: 2px solid #ecc94b; /* Yellow border */
  border-radius: 0.375rem; /* Rounded corners */
  background-color: transparent; /* Keep background transparent */
  color: white; /* Ensure text is white */
  padding: 0.5rem; /* Add some padding */
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

input::placeholder {
  color: #a0aec0; /* Light blue color for placeholder text */
}

input:focus {
  outline: none; /* Remove default focus outline */
  border-color: #fbbf24; /* Brighter yellow on focus */
  box-shadow: 0 0 0 2px rgba(251, 191, 36, 0.5); /* Add a glow effect on focus */
}